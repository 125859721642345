import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { contactModalToggle } from './features/contactmodal'

import './styles/style.css'

gsap.registerPlugin(ScrollTrigger)

contactModalToggle()

gsap.from('.logo2_wrapper', {
  y: 10,
  opacity: 0,
  duration: 0.4,
  delay: 2,
  stagger: 0.2,
})

// Select all sections that contain the .layout397_card-small elements
const layoutSections = document.querySelectorAll('.layout397_row')

layoutSections.forEach((section) => {
  // Create a GSAP animation for each section
  gsap.from(section.querySelectorAll('.layout397_card-small'), {
    y: 20,
    opacity: 0,
    duration: 0.7,
    stagger: 0.2,
    scrollTrigger: {
      trigger: section,
      start: 'top 100%',
    },
  })
})

gsap.from('.layout414_image', {
  scale: 1.1,
  duration: 1,
  stagger: 0.1,
  ease: 'power1.out',
  scrollTrigger: {
    trigger: '.layout414_content-bottom', // Use the next item as the trigger
    start: 'top 90%',
    toggleActions: 'play reverse none none',
  },
})

let portfolioItems = document.querySelectorAll('.portfolio_item')

portfolioItems.forEach((item, index) => {
  if (index < portfolioItems.length - 1) {
    // Skip the last item
    const nextItem = portfolioItems[index + 1] // Get the next item in the array

    gsap.to(item, {
      opacity: 1,
      scale: 0.8,
      scrollTrigger: {
        trigger: nextItem, // Use the next item as the trigger
        start: 'top 60%',
        end: 'bottom 0%',
        scrub: 1,
      },
    })
  }
})

function accordionMenu() {
  const groups = gsap.utils.toArray('.fs_accordion-2_item')
  const menus = gsap.utils.toArray('.fs_accordion-2_header')
  const animations = []

  groups.forEach(createAnimation)

  menus.forEach((menu) => {
    menu.addEventListener('click', () => {
      toggleAnimation(menu)
    })
  })

  function toggleAnimation(menu) {
    const icon = menu.querySelector('.fs_accordion-2_arrow-wrapper')
    const animation = menu.animation

    if (animation.reversed()) {
      // Reverse all other animations and rotate their icons back
      animations.forEach(({ anim, menu }) => {
        if (anim !== animation && !anim.reversed()) {
          anim.reverse()
          const otherIcon = menu.querySelector('.fs_accordion-2_arrow-wrapper')
          gsap.to(otherIcon, { rotate: 0, duration: 0.4, ease: 'power1.inOut' })
        }
      })

      // Play the clicked animation and rotate its icon
      animation.play()
      gsap.to(icon, { rotate: 45, duration: 0.2, ease: 'power1.inOut' })
    } else {
      // Reverse the clicked animation and rotate its icon back
      animation.reverse()
      gsap.to(icon, { rotate: 0, duration: 0.2, ease: 'power1.inOut' })
    }
  }

  function createAnimation(element) {
    const menu = element.querySelector('.fs_accordion-2_header')
    const box = element.querySelector('.fs_accordion-2_body')

    gsap.set(box, { height: 'auto' })

    // Keep a reference to the animation on the menu item itself
    const tween = gsap.from(box, {
      height: 0,
      opacity: 0,
      duration: 0.3,
      ease: 'power1.inOut',
      reversed: true,
    })

    menu.animation = tween
    animations.push({ anim: tween, menu: menu })
  }
}

accordionMenu()

window.addEventListener('load', function () {
  try {
    // Select all sections that contain the .stat3_item elements
    const statSections = document.querySelectorAll('.section_me')

    statSections.forEach((section) => {
      // Select all .stat3_item elements with the attribute fs-numbercount-element within the section
      const statItems = section.querySelectorAll(
        '.stat3_item [fs-numbercount-element]'
      )

      // Create a GSAP animation for each stat item with a number counter and stagger
      statItems.forEach((statItem) => {
        const endValue = parseInt(statItem.getAttribute('data-end'), 10) || 0
        const counter = { value: 0 }

        gsap.to(counter, {
          value: endValue,
          duration: 2,
          ease: 'power1.out',
          onUpdate: function () {
            statItem.textContent = Math.floor(counter.value)
          },
          scrollTrigger: {
            trigger: section,
            start: 'top 90%',
            once: true,
          },
        })
      })

      // Select all .stat3_progress-bar elements within the section
      const progressBars = section.querySelectorAll('.stat3_progress-bar')
      const statCard = section.querySelectorAll('.stat3_item')
      // Create a staggered animation for the stat items
      gsap.from(statCard, {
        y: 20,
        opacity: 0,
        ease: 'power1.out',
        delay: 0.5,
        duration: 0.7,
        stagger: 0.2,
        scrollTrigger: {
          trigger: section,
          start: 'top 90%',
        },
      })

      // Create a GSAP animation for the progress bars
      progressBars.forEach((progressBar) => {
        gsap.from(progressBar, {
          width: 0,
          duration: 2,
          ease: 'power1.out',
          scrollTrigger: {
            trigger: section,
            start: 'top 90%',
            once: true,
          },
        })
      })
    })
  } catch (error) {
    // Do nothing if an error occurs
  }
})
